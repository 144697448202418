import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        1969年8月10日生まれ<br />
        15年間のFreelance Video Engineerを経て<br />
        2002年夏モンタージュ入社　いつしか撮影に。<br />
        まだまだ伸びしろがあるかな<br />
        質実剛健・温故知新この2つ大切にして<br />
        まえへまえへ。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
